import {ProxyType} from 'types';

export const proxyTypeOptions: ProxyType[] = [
  'AUTO',
  'HTTP',
  'SOCKS4',
  'SOCKS5',
];

export const fakeProxy = `Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10000
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10001
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10002
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10003
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10004
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10005
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10006
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10007
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10008
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10009
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10010
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10011
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10012
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10013
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10014
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10015
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10016
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10017
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10018
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10019
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10020
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10021
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10022
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10023
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10024
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10025
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10026
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10027
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10028
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10029
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10030
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10031
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10032
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10033
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10034
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10035
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10036
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10037
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10038
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10039
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10040
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10041
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10042
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10043
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10044
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10045
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10046
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10047
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10048
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10049
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10050
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10051
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10052
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10053
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10054
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10055
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10056
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10057
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10058
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10059
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10060
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10061
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10062
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10063
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10064
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10065
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10066
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10067
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10068
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10069
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10070
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10071
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10072
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10073
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10074
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10075
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10076
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10077
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10078
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10079
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10080
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10081
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10082
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10083
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10084
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10085
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10086
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10087
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10088
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10089
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10090
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10091
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10092
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10093
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10094
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10095
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10096
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10097
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10098
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10099
Jy5vO5hkFjGGhCSyEjpL:RNW78Fm5@185.130.105.109:10100
    `;
